<style scoped>
.fill {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: scroll;
}

.fill2 {
  height: 97%;
  min-height: 97%;
  max-height: 97%;
  overflow: scroll;
}

.dndrop-container {
  min-height: 60px;
  background-color: #f5f6f8;
}

.container-left {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-y: scroll !important;
}

.container-right {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-y: scroll !important;
}

.containerWorkouts {
  padding: 20px;
  border: 2px dashed #000000;
  border-radius: 10px;
  margin-bottom: 20px;
  height: auto !important;
}

.listWorkouts {
  height: 550px;
  overflow: scroll;
}

.sticky {
  position: sticky;
  top: 0px;
}

.separator {
  color: #000000;
  height: 3px;
}

.cardDetailFood {
  min-height: 600px;
}

.boxlist {
  border-bottom: 1px solid #dfe0e1;
  cursor: pointer;
}

.boxIngredient {
  border: 1px solid #dfe0e1;
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 5px;
}

.boxlist:hover {
  background-color: #ededed;
}

.smalltext {
  font-size: 10px;
}
</style>
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { Container, Draggable } from "vue-dndrop";
import AsyncError from "@/components/exercice/AsyncError.vue";
import AsyncLoading from "@/components/exercice/AsyncLoading.vue";
const AsyncExercice = import("@/components/exercice/AsyncExercice.vue");

/**
 * Profile component
 */
export default {
  components: {
    Layout,
    Container,
    Draggable,
    AsyncComponent: () => ({
      component: AsyncExercice,
      loading: AsyncLoading,
      error: AsyncError,
      delay: 0,
      timeout: 3000,
    }),
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    majuscule: function (value) {
      if (!value) return "";
      return (value = value.toString().toUpperCase());
    },
  },
  page: {
    title: "Basic Workouts",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  async mounted() {
    //On regarde si c'est une création de nouveau programme ou une modification
    if (this.$route.name.includes("Premiumprogramnew")) {

      console.log("new");
      
      this.idUser = this.$route.params.iduser;
      this.idCoach = this.$route.params.idcoach;
      this.canDuplicate = false;

      var refUser;
      var refCoach;

      await firebase
        .firestore()
        .collection("users")
        .doc(this.idUser)
        .get()
        .then((result) => {
          this.user = result.data();
          refUser = result.ref;
        });

      await firebase
        .firestore()
        .collection("users")
        .doc(this.idCoach)
        .get()
        .then((result) => {
          this.coach = result.data();
          refCoach = result.ref;
        });

      var start = new Date(this.user.next_program_premium)
        .toISOString()
        .substr(0, 10);

      var end = new Date(start);
      end.setDate(end.getDate() + 34);
      end = end.toISOString().substr(0, 10);

      this.premiumprogram = {
        user: refUser,
        coach: refCoach,
        status: false,
        date_created: new Date().getTime(),
        range: {
          start: start,
          end: end,
        },
        workouts: [],
      };
    }

    if (this.$route.name.includes("Premiumprogramedit")) {

      console.log("edit");
      this.idProgramme = this.$route.params.idprogram;
      this.canDuplicate = true;

      await firebase
        .firestore()
        .collection("premiumprograms")
        .doc(this.idProgramme)
        .get()
        .then(async (result) => {
          this.premiumprogram = result.data();

          //On crée le duplicate program en modifiant les date pour le prochain lundi et + 5 semainess

          var d = new Date();
          d.setDate(d.getDate() + ((7 - d.getDay()) % 7) + 1);

          var start = d.toISOString().substr(0, 10);

          var end = new Date(start);
          end.setDate(end.getDate() + 34);
          end = end.toISOString().substr(0, 10);

          this.duplicateProgram = result.data();

          this.duplicateProgram.range.start = start;
          this.duplicateProgram.range.end = end;
          this.duplicateProgram.date_created = new Date().getTime();

          this.idUser = this.premiumprogram.user.id;
          await firebase
            .firestore()
            .collection("users")
            .doc(this.idUser)
            .get()
            .then((result) => {
              this.user = result.data();
            });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

      await firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .get()
        .then((result) => {
          console.log("user: " + result.data());
          this.isAdmin = result.data().role.includes("administrator")
            ? true
            : false;
        });
    }

    //On rècupère tous les anciens programmes pour voir les exercices déjà réalisé

    await firebase
      .firestore()
      .collection("users")
      .doc(this.idUser)
      .get()
      .then((result) => {
        var refUser = result.ref;
        firebase
          .firestore()
          .collection("premiumprograms")
          .where("user", "==", refUser)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((programme) => {
              programme.data().workouts.forEach((workout) => {
                workout.part_workout.forEach((part_workout) => {
                  part_workout.exercices.forEach((exercice) => {
                    this.oldExerccices.push(exercice.exercice.id);
                  });
                });
              });
            });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      });

    this.getAllExercices();
    this.getAllVideos();
  },
  data() {
    return {
      title: "Premium Program",
      idUser: null,
      user: null,
      idCoach: null,
      isAdmin: false,
      idProgramme: null,
      oldExerccices: [],
      saving_modification: false,
      saving_photo: false,
      adding_workout: false,
      deleting_workout: false,
      transloading_photo: false,
      add_workout: false,
      exercices: [],
      videos: [],
      forceUpdateList: 1,
      workouts: [],
      premiumprogram: null,
      duplicateProgram: null,
      canDuplicate: false,
      usersCoaching: [],
      uploadValue: 0,
      typeDrag: "exercices",
      typePartWorkout: [
        "Normal",
        "HIIT",
        "AFAP",
        "Pyramidal",
        "Video",
        "Running",
      ],
      typesWorkout: [
        "VIDEO",
        "VIDEOLIVE",
        "NORMAL",
        "HIIT",
        "PYRAMIDAL",
        "AFAP",
        "CARDIO",
      ],
      muscles: [
        "Legs",
        "Abs",
        "Chest",
        "Back",
        "Shoulders",
        "Biceps",
        "Triceps",
        "Cardio",
        "Stretching",
        "Warming up"
      ],
      musclesSelect: [
        "Legs",
        "Abs",
        "Chest",
        "Back",
        "Shoulders",
        "Biceps",
        "Triceps",
        "Cardio",
        "Stretching",
        "Warming up"
      ],
      niveaux: ["Easy", "Medium", "Hard"],
      niveauxSelect: ["Easy", "Medium", "Hard"],
      materiels: [
        "Bodyweight",
        "Dumbbells or Bottles",
        "Kettlebell",
        "Barbell",
        "Jumping rope",
        "Weight plate",
        "Tapis roulant",
        "Elliptical",
        "Stepper",
        "Bike",
        "Stairs",
        "Rower",
        "Fitball",
        "Elastic",
        "Bench",
        "Gym",
        "Medecine Ball",
        "Multifunction",
        "Ankles",
        "TRX",
        "Outdoor",
        "Step",
        "Chair",
      ],
      materielsSelect: [
        "Bodyweight",
        "Dumbbells or Bottles",
        "Kettlebell",
        "Barbell",
        "Jumping rope",
        "Weight plate",
        "Tapis roulant",
        "Elliptical",
        "Stepper",
        "Bike",
        "Stairs",
        "Rower",
        "Fitball",
        "Elastic",
        "Bench",
        "Gym",
        "Medecine Ball",
        "Multifunction",
        "Ankles",
        "TRX",
        "Outdoor",
        "Step",
        "Chair",
      ],
      optionsRunning: ["Walk", "Running"],
      optionsTypeObjectifRunning: ["minutes", "km"],
      optionsTypeRepetExo: ["Reps", "Secondes", "Minutes"],
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      items: [
        {
          text: "Premium Program",
        },
        {
          text: "premiumprogram",
          active: true,
        },
      ],
    };
  },
  methods: {
    duplicatePrograms() {
      var db = firebase.firestore();

      var userToDuplicate = db
        .collection("users")
        .doc(document.getElementById("userToDuplicate").value);

      var coachDuplicate = db.collection("users").doc("8");

      this.duplicateProgram.user = userToDuplicate;
      this.duplicateProgram.status = false;
      this.duplicateProgram.coach = coachDuplicate;

      firebase.firestore().collection("premiumprograms").add(this.duplicateProgram);
      this.hideModalDuplicate();
    },
    showModalDuplicate() {
      this.loadUsersCoaching();
      this.$refs["modal-duplicate"].show();
    },
    hideModalDuplicate() {
      this.$refs["modal-duplicate"].hide();
    },
    loadUsersCoaching() {
      var db = firebase.firestore();
      var requete = db
        .collection("users")
        .orderBy("first_name", "asc")
        .where("role", "array-contains", "premium");

      requete
        .get()
        .then((users) => {
          this.usersCoaching = [];

          users.forEach(async (user) => {
            this.usersCoaching.push({
              id: user.id,
              data: user.data(),
              ref: user.ref,
            });
          });
          //document.getElementById("savemodifications").style.display = "none";
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    countOccurrences(arr, val) {
      return arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
    },
    changeTypeDrag(type) {
      this.typeDrag = type;
    },
    onDrop(keyworkout, keypart, dropResult) {
      if (
        dropResult.element._prevClass == "droppable-item" &&
        dropResult.addedIndex != null
      ) {
        //L'index de l'ingredient (dans le tableau ingredient) qui a été draggé
        var indexItem = dropResult.payload;

        var itemSelect = null;
        if (this.typeDrag == "exercices")
          itemSelect = this.exercices[indexItem];

        if (this.typeDrag == "videos") itemSelect = this.videos[indexItem];

        //Dans quel index du tableau des ingredients de la recette on drop l'élément draggé
        var indexDansWorkout = dropResult.addedIndex;

        var newExercice = {
          exercice: firebase
            .firestore()
            .doc(this.typeDrag + "/" + itemSelect.id),
          repetition_exercice: 0,
          type_rep_exercice: "Reps",
          set_exercice: 0,
          recuperation_exercice: 0,
          recuperation_set: 0,
          comments_exercice_coach: null,
        };

        //On transforme maintenant le JSON d'ingrédient dans la recette en array
        var tabExercicesWorkout =
          this.premiumprogram.workouts[keyworkout].part_workout[keypart]
            .exercices;
        tabExercicesWorkout.splice(indexDansWorkout, 0, newExercice);

        //On réinsère les ingredients dans la recette
        this.premiumprogram.workouts[keyworkout].part_workout[
          keypart
        ].exercices = tabExercicesWorkout;

        this.forceUpdateList++;
      }
      if (dropResult.element._prevClass == "draggable-item") {
        var anciennePosition = dropResult.removedIndex;
        var newPosition = dropResult.addedIndex;

        console.log("workout n°" + keyworkout + "  |   part n°" + keypart);

        var array =
          this.premiumprogram.workouts[keyworkout].part_workout[keypart]
            .exercices;

        this.premiumprogram.workouts[keyworkout].part_workout[
          keypart
        ].exercices = this.move(array, anciennePosition, newPosition);

        this.forceUpdateList++;
      }
    },
    move(input, from, to) {
      let numberOfDeletedElm = 1;

      const elm = input.splice(from, numberOfDeletedElm)[0];

      numberOfDeletedElm = 0;

      input.splice(to, numberOfDeletedElm, elm);

      return input;
    },
    getChildPayload1(id) {
      return id;
    },
    getChildPayload2(id) {
      return id;
    },

    addNewWorkout() {
      var isVide = false;

      this.premiumprogram.workouts.forEach((workout) => {
        workout["part_workout"].forEach((partWorkout) => {
          if (partWorkout.exercices.length == 0) isVide = true;
        });
      });

      if (isVide && this.premiumprogram.workouts.length != 0) return;

      var newWorkout = {
        id_workout: Math.floor(Math.random() * Date.now()),
        name_workout: "",
        part_workout: [
          {
            name_part_workout: "",
            type: "Normal",
            type_hiit: {
              rounds_hiit: 0,
              work_time: 0,
              rest_time_exercice: 0,
              rest_time_round: 0,
            },
            type_pyramidal: {
              start_pyramidal: 0,
              finish_pyramidal: 0,
              oneway: false,
            },
            type_running: {
              type_effort: "Running",
              objectif: 0,
              type_objectif: "km",
              objectif_vitesse: null,
            },
            exercices: [],
          },
        ],
      };

      this.premiumprogram.workouts.push(newWorkout);
    },
    addNewPartWorkout(index) {
      var isVide = false;

      this.premiumprogram.workouts.forEach((workout) => {
        workout["part_workout"].forEach((partWorkout) => {
          if (partWorkout.exercices.length == 0) isVide = true;
        });
      });

      if (isVide) return;

      var newPartWorkout = {
        name_part_workout: "",
        type: "Normal",
        type_hiit: {
          rounds_hiit: 0,
          work_time: 0,
          rest_time_exercice: 0,
          rest_time_round: 0,
        },
        type_pyramidal: {
          start_pyramidal: 0,
          finish_pyramidal: 0,
          oneway: false,
        },
        type_running: {
          type_effort: "Running",
          objectif: 0,
          type_objectif: "km",
          objectif_vitesse: null,
        },
        exercices: [],
      };

      this.premiumprogram.workouts[index].part_workout.push(newPartWorkout);
    },
    searchVideo() {
      var search = document.getElementById("searchvideo").value.toLowerCase();
      //filtrer en javascript

      document.querySelectorAll("[data-type='video']").forEach((element) => {
        var id_video = element.dataset.id;
        //On récupère le JSON de l'ingredient avec son id
        let video = this.videos.find((el) => el.id === id_video);
        //On regarde si la chaine de caractère est comprise dans le nom d'une des langues pour afficher ou masquer
        if (
          video.data.description.it.nom.toLowerCase().includes(search) ||
          video.data.description.fr.nom.toLowerCase().includes(search) ||
          video.data.description.us.nom.toLowerCase().includes(search) ||
          search == ""
        )
          element.style = "display: block";
        else element.style = "display: none";
      });
    },
    findCommonElements(arr1, arr2) {
      return arr1.some((item) => arr2.includes(item));
    },
    searchExercice() {
      var search = document
        .getElementById("searchexercice")
        .value.toLowerCase();
      var muscle = document.getElementById("searchMuscle").value;
      //filtrer en javascript

      document.querySelectorAll("[data-type='exercice']").forEach((element) => {
        var id_exercice = element.dataset.id;
        //On récupère le JSON de l'ingredient avec son id
        let exercice = this.exercices.find((el) => el.id === id_exercice);

        //On regarde si la chaine de caractère est comprise dans le nom d'une des langues pour afficher ou masquer
        if (
          (exercice.data.description.it.nom.toLowerCase().includes(search) ||
            exercice.data.description.fr.nom.toLowerCase().includes(search) ||
            exercice.data.description.us.nom.toLowerCase().includes(search) ||
            search == "") &&
          (this.findCommonElements(exercice.data.muscles, this.musclesSelect) ||
            muscle == "all") &&
          this.findCommonElements(exercice.data.materiels, this.materielsSelect)
        ) {
          element.style = "display: block";
        } else {
          element.style = "display: none";
        }
      });
    },
    getAllExercices() {
      var db = firebase.firestore();
      db.collection("exercices")
        .orderBy("description.it.nom")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.exercices.push({ id: doc.id, data: doc.data() });
          });
        });
    },
    getAllVideos() {
      var db = firebase.firestore();
      db.collection("videos")
        .orderBy("description.it.nom")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.videos.push({ id: doc.id, data: doc.data() });
          });
        });
    },
    showExerciceFromRef(refExercice) {
      refExercice.get().then((data) => {
        data;
      });
    },
    hideModal() {
      this.$refs["delete-modal"].hide();
    },
    showModal() {
      this.$refs["delete-modal"].show();
    },
    deleteExerciceList(keyworkout, key, index) {
      this.premiumprogram.workouts[keyworkout].part_workout[
        key
      ].exercices.splice(index, 1);
      this.forceUpdateList++;
    },
    deletePartWorkout(key, keyworkout) {
      this.premiumprogram.workouts[keyworkout].part_workout.splice(key, 1);
      this.forceUpdateList++;
    },
    deleteWorkout(keyworkout) {
      this.premiumprogram.workouts.splice(keyworkout, 1);
      this.forceUpdateList++;
    },
    async saveModificationProgram() {
      //Si c'est un nouveau programme, on crée
      if (this.$route.name.includes("Premiumprogramnew")) {
        var idprogram = await firebase
          .firestore()
          .collection("premiumprograms")
          .add(this.premiumprogram);
        this.$router.push({
          path: "/premiumprogram/edit/" + idprogram.id,
        });
      }

      //Si c'est un nouveau programme, on crée
      if (this.$route.name.includes("Premiumprogramedit")) {
        this.saving_modification = true;
        await firebase
          .firestore()
          .collection("premiumprograms")
          .doc(this.idProgramme)
          .update(this.premiumprogram)
          .then(() => {
            this.saving_modification = false;
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <b-modal
      ref="modal-duplicate"
      size="lg"
      hide-footer
      title="New Duplication"
      
    >
      <div class="d-block text-center" v-if="canDuplicate == true">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col mb-3">
                  <b-form-input
                    v-model="duplicateProgram.range.start"
                    type="date"
                  ></b-form-input>
                </div>
                <div class="col">
                  <b-form-input
                    v-model="duplicateProgram.range.end"
                    type="date"
                  ></b-form-input>
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                  <select id="userToDuplicate">
                    <option
                      v-for="user in usersCoaching"
                      :value="user.id"
                      :key="user.id"
                    >
                      {{ user.data["first_name"] }} {{ user.data["last_name"] }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="d-grid gap-2" v-if="this.duplicateProgram != null && canDuplicate == true">
                <button
                  class="btn btn-primary mt-2"
                  type="button"
                  @click="duplicatePrograms()"
                >
                  Duplicate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <div class="row fill">
      <div class="col-3 container-left">
        <b-tabs content-class="mt-3" fill>
          <b-tab title="Exercices" active @click="changeTypeDrag('exercices')">
            <input
              type="text"
              class="form-control"
              id="searchexercice"
              placeholder="Search exercice..."
              @keyup="searchExercice()"
            />
            <div class="container p-0">
              <div class="row pt-2">
                <div class="col">
                  <b-form-select
                    @change="searchExercice()"
                    multiple
                    id="searchMuscle"
                    :options="muscles"
                    v-model="musclesSelect"
                    style="width: 100%; font-size: 10px"
                    :select-size="10"
                  ></b-form-select>
                </div>
                <div class="col">
                  <b-form-select
                    @change="searchExercice()"
                    multiple
                    id="searchMateriel"
                    v-model="materielsSelect"
                    :options="materiels"
                    style="width: 100%; font-size: 10px"
                    :select-size="10"
                  ></b-form-select>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <Container
                class="item"
                behaviour="copy"
                group-name="1"
                :get-child-payload="getChildPayload1"
              >
                <Draggable v-for="exercice in exercices" :key="exercice.id">
                  <div
                    class="droppable-item"
                    data-type="exercice"
                    :data-id="exercice.id"
                  >
                    <div class="container boxlist px-0 py-3">
                      <div class="row">
                        <div class="col-auto position-relative">
                          <img
                            :src="exercice.data.photo.thumbnail"
                            width="50px"
                          />
                          <span
                            v-if="oldExerccices.includes(exercice.id)"
                            class="
                              position-absolute
                              bottom-80
                              start-90
                              translate-middle
                              badge
                              rounded-pill
                              bg-danger
                            "
                          >
                            {{ countOccurrences(oldExerccices, exercice.id) }}
                            <span class="visually-hidden">unread messages</span>
                          </span>
                        </div>
                        <div class="col">
                          <p class="fw-bold mb-0">
                            {{ exercice.data.description.it.nom }}
                          </p>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Draggable>
              </Container>
            </div>
          </b-tab>
          <b-tab title="Videos" @click="changeTypeDrag('videos')">
            <input
              type="text"
              class="form-control"
              id="searchvideo"
              placeholder="Search video..."
              @keyup="searchVideo()"
            />
            <div class="listVideos mt-3">
              <Container
                class="item"
                behaviour="copy"
                group-name="1"
                :get-child-payload="getChildPayload2"
              >
                <Draggable v-for="video in videos" :key="video.id">
                  <div
                    class="droppable-item"
                    data-type="video"
                    :data-id="video.id"
                  >
                    <div class="container boxlist px-0 py-2">
                      <div class="row">
                        <div class="col-auto">
                          <img :src="video.data.photo.thumbnail" width="50px" />
                        </div>
                        <div class="col">
                          <p class="fw-bold mb-0">
                            {{ video.data.description.it.nom }}
                          </p>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Draggable>
              </Container>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <div class="col-9 container-right">
        <div class="card fill2">
          <div v-if="premiumprogram == null" class="text-center">
            Loading...
          </div>
          <div v-if="premiumprogram != null" class="p-3">
            <div class="container alert alert-warning">
              <div class="row">
                <div class="col-6">
                  <h3 class="mb-0">
                    {{ user.first_name }} {{ user.last_name }}
                  </h3>
                  <p>
                    <small
                      ><strong>Weight:</strong> {{ user.first_weight }}kg (1er)
                      -> {{ user.poids_actuel }}kg (Actual) ->
                      {{ user.target }}kg (Target)</small
                    >
                    <br />
                    <small
                      ><strong>Note coach:</strong>
                      {{ user.note_user_coach }}</small
                    >
                  </p>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col mb-3">
                      <b-form-input
                        v-model="premiumprogram.range.start"
                        type="date"
                      ></b-form-input>
                    </div>
                    <div class="col">
                      <b-form-input
                        v-model="premiumprogram.range.end"
                        type="date"
                      ></b-form-input>
                    </div>
                  </div>
                  <b-form-checkbox
                    v-model="premiumprogram.status"
                    :disabled="isAdmin ? false : true"
                  >
                    Publish this program
                  </b-form-checkbox>
                  <div class="d-grid gap-2">
                    <button
                      class="btn btn-success"
                      type="button"
                      @click="saveModificationProgram()"
                    >
                      <span
                        v-if="saving_modification"
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Save Modifications
                    </button>
                  </div>

                  <div class="d-grid gap-2 mt-3" v-if="isAdmin">
                    <button
                      class="btn btn-danger"
                      type="button"
                      @click="showModalDuplicate()"
                    >
                      Duplicate
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="container px-0">
              <div class="row">
                <div class="col-12">
                  <div class="container px-0 py-2">
                    <div class="alert alert-info text-center" role="alert">
                      <i class="fas fa-magic"></i> Creator Tool
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div
                          class="containerWorkouts"
                          v-for="(
                            workout, keyworkout
                          ) in premiumprogram.workouts"
                          :key="'workout' + keyworkout"
                        >
                          <div
                            class="container alert alert-warning"
                            role="alert"
                          >
                            <div class="row">
                              <div class="col-8">
                                <b-form-input
                                  v-model="workout.name_workout"
                                  placeholder="Name Workout"
                                ></b-form-input>
                              </div>
                              <div class="col-4">
                                <div class="d-grid gap-2">
                                  <button
                                    class="btn btn-danger"
                                    type="button"
                                    @click="deleteWorkout(keyworkout)"
                                  >
                                    <i class="fas fa-trash-alt"></i> Delete
                                    Workout
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="containerPartieWorkout">
                            <div class="groups">
                              <div
                                v-for="(partie, key) in workout.part_workout"
                                :key="'partieworkout' + key"
                              >
                                <div class="group">
                                  <div
                                    class="container alert alert-dark p-1 mb-1"
                                  >
                                    <div class="row">
                                      <div class="col-6">
                                        <b-form-input
                                          size="sm"
                                          v-model="partie.name_part_workout"
                                          placeholder="Name Part Workout"
                                        ></b-form-input>
                                      </div>
                                      <div class="col-4">
                                        <b-form-select
                                          v-model="partie.type"
                                          :options="typePartWorkout"
                                          text-field="type"
                                          style="width: 100%"
                                        ></b-form-select>
                                      </div>
                                      <div class="col-2">
                                        <div class="d-grid gap-2">
                                          <button
                                            class="btn btn-sm btn-danger"
                                            type="button"
                                            @click="
                                              deletePartWorkout(key, keyworkout)
                                            "
                                          >
                                            <i class="fas fa-trash-alt"></i>
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="container alert alert-primary my-2"
                                    role="alert"
                                    v-if="partie.type == 'HIIT'"
                                  >
                                    <div class="row">
                                      <div class="col">
                                        <small>Giri</small><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_hiit.rounds_hiit
                                          "
                                          placeholder="Giri"
                                        ></b-form-input>
                                      </div>
                                      <div class="col">
                                        <small>Lavoro (sec)</small><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_hiit.work_time
                                          "
                                          placeholder="Lavoro (sec)"
                                        ></b-form-input>
                                      </div>
                                      <div class="col">
                                        <small>Recupero (sec)</small><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_hiit.rest_time_exercice
                                          "
                                          placeholder="Recupero (sec)"
                                        ></b-form-input>
                                      </div>
                                      <div class="col">
                                        <small>Recupero tra i giri (sec)</small
                                        ><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_hiit.rest_time_round
                                          "
                                          placeholder="Recupero tra i giri (sec)"
                                        ></b-form-input>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="container alert alert-primary my-2"
                                    role="alert"
                                    v-if="partie.type == 'Pyramidal'"
                                  >
                                    <div class="row">
                                      <div class="col">
                                        <small>Start</small><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_pyramidal
                                              .start_pyramidal
                                          "
                                          placeholder="Giri"
                                        ></b-form-input>
                                      </div>
                                      <div class="col">
                                        <small>Finish</small><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_pyramidal
                                              .finish_pyramidal
                                          "
                                          placeholder="Lavoro (sec)"
                                        ></b-form-input>
                                      </div>
                                      <div class="col">
                                        <small>One Way?</small><br />
                                        <b-form-checkbox
                                          v-model="partie.type_pyramidal.oneway"
                                          name="oneway"
                                        >
                                          Onway
                                        </b-form-checkbox>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="alert alert-primary my-2"
                                    role="alert"
                                    v-if="partie.type == 'Running'"
                                  >
                                    <div class="row">
                                      <div class="col">
                                        <small>Run/Walk</small><br />
                                        <b-form-select
                                          v-model="
                                            partie.type_running.type_effort
                                          "
                                          :options="optionsRunning"
                                          style="width: 100%"
                                        ></b-form-select>
                                      </div>
                                      <div class="col">
                                        <small>Objectif</small><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_running.objectif
                                          "
                                          placeholder="Objectif"
                                        ></b-form-input>
                                      </div>
                                      <div class="col">
                                        <small>Type</small><br />
                                        <b-form-select
                                          v-model="
                                            partie.type_running.type_objectif
                                          "
                                          :options="optionsTypeObjectifRunning"
                                          style="width: 100%"
                                        ></b-form-select>
                                      </div>
                                      <div class="col">
                                        <small>Vitesse (facultatif)</small
                                        ><br />
                                        <b-form-input
                                          type="number"
                                          size="sm"
                                          v-model.number="
                                            partie.type_running.objectif_vitesse
                                          "
                                          placeholder="Objectif vitesse"
                                        ></b-form-input>
                                      </div>
                                    </div>
                                  </div>
                                  <Container
                                    @drop="onDrop(keyworkout, key, $event)"
                                    :key="
                                      'exercicesList' + workout.id + '-' + key
                                    "
                                    v-if="partie.type != 'Running'"
                                    group-name="1"
                                  >
                                    <Draggable
                                      v-for="(
                                        exercicepart, index
                                      ) in partie.exercices"
                                      :key="'exerciceList' + index"
                                    >
                                      <div class="draggable-item">
                                        <div
                                          class="
                                            boxIngredient
                                            container
                                            align-items-center
                                          "
                                          :id="'boxExerciceWorkout' + index"
                                        >
                                          <div class="row">
                                            <div class="col-4">
                                              <async-component
                                                :key="
                                                  'exercice-' +
                                                  key +
                                                  index +
                                                  forceUpdateList
                                                "
                                                :exerciceref="
                                                  exercicepart.exercice
                                                "
                                                :exercicetype="partie.type"
                                              ></async-component>
                                            </div>

                                            <div class="col-5">
                                              <div
                                                class="row"
                                                v-if="partie.type == 'AFAP'"
                                              >
                                                <div class="col-6">
                                                  <small>rep</small><br />
                                                  <b-form-input
                                                    v-model.number="
                                                      exercicepart.repetition_exercice
                                                    "
                                                    size="sm"
                                                    placeholder="rep"
                                                  ></b-form-input>
                                                </div>
                                                <div class="col-6">
                                                  <small>type rep</small><br />
                                                  <b-form-select
                                                    v-model="
                                                      exercicepart.type_rep_exercice
                                                    "
                                                    :options="
                                                      optionsTypeRepetExo
                                                    "
                                                    size="sm"
                                                    style="width: 100%"
                                                  ></b-form-select>
                                                </div>
                                              </div>
                                              <div
                                                class="row"
                                                v-if="partie.type == 'Normal'"
                                              >
                                                <div
                                                  class="col-2 p-0"
                                                  style="
                                                    padding-right: 2px !important;
                                                  "
                                                >
                                                  <small>Series</small><br />
                                                  <b-form-input
                                                    v-model.number="
                                                      exercicepart.set_exercice
                                                    "
                                                    size="sm"
                                                    placeholder="series"
                                                    type="number"
                                                  ></b-form-input>
                                                </div>
                                                <div
                                                  class="col-2 p-0"
                                                  style="
                                                    padding-right: 2px !important;
                                                  "
                                                >
                                                  <small>rep</small><br />
                                                  <b-form-input
                                                    v-model.number="
                                                      exercicepart.repetition_exercice
                                                    "
                                                    size="sm"
                                                    placeholder="rep"
                                                  ></b-form-input>
                                                </div>
                                                <div
                                                  class="col-2 p-0"
                                                  style="
                                                    padding-right: 2px !important;
                                                  "
                                                >
                                                  <small>type rep</small><br />
                                                  <b-form-select
                                                    v-model="
                                                      exercicepart.type_rep_exercice
                                                    "
                                                    :options="
                                                      optionsTypeRepetExo
                                                    "
                                                    size="sm"
                                                    style="width: 100%"
                                                  ></b-form-select>
                                                </div>
                                                <div
                                                  class="col-3 p-0"
                                                  style="
                                                    padding-right: 2px !important;
                                                  "
                                                >
                                                  <small>Rec/serie</small><br />
                                                  <b-form-input
                                                    v-model.number="
                                                      exercicepart.recuperation_set
                                                    "
                                                    size="sm"
                                                    placeholder="rec/serie"
                                                  ></b-form-input>
                                                </div>
                                                <div
                                                  class="col-3 p-0"
                                                  style="
                                                    padding-right: 2px !important;
                                                  "
                                                >
                                                  <small>Rec/Ex</small><br />
                                                  <b-form-input
                                                    v-model.number="
                                                      exercicepart.recuperation_exercice
                                                    "
                                                    size="sm"
                                                    placeholder="rec/serie"
                                                  ></b-form-input>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-2">
                                              <small>Comment</small><br />
                                              <b-form-input
                                                v-model="
                                                  exercicepart.comments_exercice_coach
                                                "
                                                size="sm"
                                                placeholder="Comment"
                                              ></b-form-input>
                                            </div>
                                            <div class="col-1">
                                              <small>Delete?</small><br />
                                              <button
                                                type="button"
                                                class="btn btn-secondary btn-sm"
                                                @click="
                                                  deleteExerciceList(
                                                    keyworkout,
                                                    key,
                                                    index
                                                  )
                                                "
                                              >
                                                <i class="fas fa-trash"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Draggable>
                                  </Container>

                                  <hr class="separator" />
                                </div>
                              </div>
                            </div>

                            <div class="d-grid gap-2 mt-4">
                              <button
                                class="btn btn-primary"
                                type="button"
                                @click="addNewPartWorkout(keyworkout)"
                              >
                                + Add part
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="d-grid gap-2">
                          <button
                            class="btn btn-warning"
                            type="button"
                            @click="addNewWorkout()"
                          >
                            Add New Workout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
